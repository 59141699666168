<template>
    <div class="text-center" v-if="!is_data_ready">
       <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="tab-pane fade show active text-center" v-else-if="Object.keys(inning).length === 0">
        <p>{{ $t("No scorecard data found") }}.</p>
    </div>
    <div v-else>
        <div class="innings_tabs cr-submain-tab">
            <ul>
                <li v-for="(inning,index) in innings" :key="inning.order">
                    <a :class="activeInningIndex == index ? 'selected':''" style="cursor: pointer" @click=" activeInningIndex = index; getScorecard(inning)" href="javascript:;">
                        <span>{{ inning.caption }}
                        </span>
                    </a>
                </li>
            </ul>
            <div class="clearfix"></div>
        </div>
        <div class="batsman_score_table cr-global-table">
            <div class="table-responsive">
                <table>
                    <thead>
                        <tr>
                            <th class="text-left" id="batting-body">
                                <span>{{ $t("cric.Batsman") }}</span>
                            </th>
                            <th> R</th>
                            <th> B</th>
                            <th class="only-hide-mobile"> 4s</th>
                            <th class="only-hide-mobile"> 6s</th>
                            <th> SR</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr v-for="batsman in inning.cricMatch.cricBattings" :key="batsman.id">
                        <td class="text-left">
                            <span :class="(batsman.type == 'Not Out') ? 'green-text tb-title' : 'tb-title'">{{batsman.player.name}}</span>
                            <p class="tb-text" v-if="batsman.type == 'Retired hurt'"> retired hurt</p>
                            <p class="tb-text" v-else-if="batsman.bowler || batsman.fielder">
                                {{ batsman.type.startsWith('Catch OUT') ? 'c'
                                : batsman.type == 'OUT stumped' ? 'st'
                                : batsman.type.startsWith('Run OUT') ? 'r'
                                : batsman.type == 'LBW' ? 'lbw' : '' }}
                                {{ batsman.fielder.name || '' }}
                                {{ batsman.bowler ? `b ${batsman.bowler.name}` : '' }}
                            </p>
                            <p class="tb-text"  v-else> {{ batsman.type }}</p>
                        </td>

                        <td><strong>{{((batsman.score == '' || batsman.score == 0) && (batsman.type=='Yet to bat'|| batsman.type=='Did not bat')) ? '' : batsman.score}}</strong></td>
                        <td> {{((batsman.balls == '' || batsman.balls == 0) && (batsman.type=='Yet to bat'|| batsman.type=='Did not bat')) ? '' : batsman.balls}}</td>
                        <td class="only-hide-mobile"> {{((batsman.four == '' || batsman.four == 0) && (batsman.type=='Yet to bat'|| batsman.type=='Did not bat')) ? '' : batsman.four}}</td>
                        <td class="only-hide-mobile"> {{((batsman.six == '' || batsman.six == 0) && (batsman.type=='Yet to bat'|| batsman.type=='Did not bat')) ? '' : batsman.six}}</td>
                        <td> {{((batsman.runrate == '' || batsman.runrate == 0) && (batsman.type=='Yet to bat'|| batsman.type=='Did not bat')) ? '' : batsman.runrate}}</td></tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="cr-global-table batsman_score_table_details">
            <div class="table-responsive">
                <table>
                    <tbody>
                    <tr>
                        <td>EXT: {{inning.sum}}</td>
                        <td class="only-hide-mobile"> B: {{inning.bye}}</td>
                        <td class="only-hide-mobile"> LB: {{inning.legbye}}</td>
                        <td class="only-hide-mobile"> NB: {{inning.noball}}</td>
                        <td class="only-hide-mobile"> WD: {{inning.wide}}</td>
                        <td class="only-hide-mobile"> P: {{inning.penalty}}</td>
                        <td><span class="green-text">TOT: </span><span v-if="inning && inning.score">{{inning.score}}/{{inning.wickets}} ({{inning.overs}})</span></td>
                        <td><span class="green-text">RR:</span> {{inning.runrate}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="cr-global-table batsman_score_table">
            <div class="table-responsive">
                <table>
                    <thead>
                        <tr>
                            <th class="text-left"><span>{{ $t("cric.Bowler") }}</span></th>
                            <th> O</th>
                            <th class="only-hide-mobile"> M</th>
                            <th> R</th>
                            <th> W</th>
                            <th class="only-hide-mobile"> NB</th>
                            <th class="only-hide-mobile"> WD</th>
                            <th class="only-hide-mobile"> ER</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr v-for="bowler in inning.cricMatch.cricBowlings">
                        <td class="text-left"><span class="tb-title">{{bowler.player.name}}</span></td>
                        <td> {{bowler.overs}}</td>
                        <td class="only-hide-mobile"> {{bowler.mdns}}</td>
                        <td v-if="bowler && bowler.score"><strong>{{bowler.score}}</strong></td>
                        <td><strong>{{bowler.wickets}}</strong></td>
                        <td class="only-hide-mobile"> {{bowler.noball}}</td>
                        <td class="only-hide-mobile"> {{bowler.wide}}</td>
                        <td class="only-hide-mobile"> {{bowler.runrate}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="cr-global-table batsman_score_table_bating_details" v-if="inning.cricMatch.cricPartnerships.length > 0">
            <div class="table-responsive">
                <table>
                    <thead>
                        <tr>
                            <th class="text-left"> {{ $t("cric.Partnership") }}</th>
                            <th> R (B)</th>
                            <th> R (B)</th>
                            <th class="only-hide-mobile"> EX</th>
                            <th> {{ $t("cric.Total") }}</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr v-for="partnership in inning.cricMatch.cricPartnerships" :key="partnership.id">
                        <td class="text-left"> <span class="tb-title">{{partnership.player1.name}} / {{partnership.player2.name}}</span></td>
                        <td><b>{{partnership.p1_run}}</b> ({{partnership.p1_ball}})</td>
                        <td><b>{{partnership.p2_run}}</b> ({{partnership.p2_ball}})</td>
                        <td class="only-hide-mobile"> {{partnership.extra}}</td>
                        <td><b>{{partnership.total_run}}</b> ({{partnership.total_ball}})</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="cr-global-table macth_info_tab">
            <table>
                <tbody>
                <tr>
                    <td><span class="tb-title">Toss</span></td>
                    <td><p style="display:inline"> {{inning.cricMatch.toss_win}} won the toss and choose
                        to </p><p style="display:inline">{{inning.cricMatch.elected}}</p></td>
                </tr>
                <tr>
                    <td><b> {{ $t("cric.Umpires") }} </b></td>
                    <td>
                        <p v-for="umpire in inning.cricMatch.cricMatchUmpires" :key="umpire.id"> {{umpire.cricUmpire.name}} ({{umpire.position}}) </p>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        name: "scorecard",

        data() {
            return {
                innings:'',
                inning:[],
                activeInningIndex:0,    //active tab inning
                matchCurrentInning:0,
                inningsInterval: null
            }
        },
        mounted() {
            this.main();
            var _this = this;
            this.$root.$on('CricMatchUpdated', data => {
                if (data.match_id == _this.$route.params.id && _this.activeInningIndex == _this.matchCurrentInning) {
                    _this.getScorecard(_this.innings[_this.innings.length - 1])
                }
            });
            this.inningsInterval = setInterval(() => {
                this.innings = this.$store.getters.getMatchDetailResponse.innings;
            }, 60000);
        },
        beforeDestroy() {
            this.$root.$off('CricMatchUpdated')
            clearInterval(this.inningsInterval);
        },
        serverPrefetch() {
            return this.main();
        },
        metaInfo() {
            let ss = this.$store.state.siteSetting;
            var replacements = {
                '%HOME_TEAM%': this.$store.getters.getMatchDetailResponse.team1.name,
                '%AWAY_TEAM%': this.$store.getters.getMatchDetailResponse.team2.name,
            };

            let title = this.$getSafe(() => this.$replacePlaceHolders(ss.cricket_match_scorecard_meta_title,replacements));
            let description = this.$getSafe(() => this.$replacePlaceHolders(ss.cricket_match_scorecard_meta_description,replacements));
            let keywords = this.$getSafe(() => ss.cricket_match_scorecard_meta_keywords);

            return {
                title: title,
                description: description,
                meta: this.$getMetaTags(title, description, keywords),
                link:[
                    {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}],
            };
        },
        watch: {
              $route() {
                  this.main();
              }
          },
        methods: {
            getScorecard(inning) {
                if (inning.order && inning.team_id) {
                    this.is_data_ready = false;
                    return this.$axios
                        .get(this.$MATCH_SCORECARD_API.replace(':match', this.$route.params.slug), {
                            params: {
                                inning: inning.order,
                                team: inning.team_id,
                            }
                        })
                        .then(response => {
                            if(response.data.result) {
                                this.inning = response.data.result.inning;
                                // console.log(this.inning);
                            }
                            this.is_data_ready = true;
                        })
                }
            },
            main() {
                this.innings = this.$store.getters.getMatchDetailResponse.innings;
                if(this.innings[0] && this.innings[0].order){
                    this.matchCurrentInning = this.activeInningIndex = this.innings.length - 1
                    return this.getScorecard(this.innings[this.innings.length - 1])

                }else {
                    this.is_data_ready = true
                }
            }
        }
    }
</script>
